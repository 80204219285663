import './firm.scss';
import { useRelativePathsToBasePath } from '../../hooks';
import {
  fetchCurrentOrganization,
  fetchOrganizations,
  type Filters,
  type FilterStoreState,
  type OrganizationsStoreState,
  resetDisplayedOrganizations,
  selectOrganization } from '../../store/slices';
import { FileViewModal } from './FileViewModal/FileViewModal';
import {
  ROUTE_ARRAY,
} from './routes';
import userApi from 'api/user';
import {
  DownloadFilesModal,
  Filters as FiltersComponent,
  RouteMatcher,
  Spinner,
  Support,
} from 'components';
import { ReportSelectorModal } from 'components/ReportsModule/ReportSelectors';
import { BlockDisplayProvider } from 'components/SettingsModule/contexts/BlockDisplayContext.context';
import { SettingsModule } from 'components/SettingsModule/SettingsModule';
import {
  GlobalElementBusContext,
  GlobalElementBusContextProvider,
  ModalActivationContext,
  ModalActivationProvider,
} from 'contexts';
import { Error } from 'pages/Error';
import {
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { type AppDispatch } from 'store';

const VALID_PATHS_FOR_CA_BG = [
  '/firm/capital-accounts/*',
];

const Firm = () => {
  const { valid } = useRelativePathsToBasePath(...VALID_PATHS_FOR_CA_BG);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParameters = new URLSearchParams(search);
  const { fileViewId } = Object.fromEntries(queryParameters.entries());

  const {
    fileViewModal,
    showDownloadsModal,
    showPerformanceReportsModal,
    showSettingsModal,
    showSupportModal,
    toggleFileViewModal,
  } = useContext(ModalActivationContext);
  const containerRef = useRef(null);
  const {
    addRef,
  } = useContext(GlobalElementBusContext);
  const isLoading = useSelector<
  { global: { loading: boolean, }, }>(
    (state) => state.global.loading,
  ) as boolean;
  const {
    displayedOrganization,
    organizations,
  } = useSelector<{ organizations: OrganizationsStoreState, }>(
    (state) => state.organizations,
  ) as OrganizationsStoreState;

  const activeFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.activeFilters) as Filters | null;

  useEffect(() => {
    // open file directly through url
    if (fileViewId) {
      toggleFileViewModal(true,
        {
          // fileData will be fetched inside FileViewModal
          allDocumentsInGroup: [],
          fileNameProps: null,
          id: fileViewId,
        });
    }
  }, [
    fileViewId,
    toggleFileViewModal,
  ]);

  useEffect(() => {
    if (!activeFilters) {
      navigate('/firms');
      return;
    }

    if (!organizations?.length) {
      dispatch(fetchOrganizations());
      dispatch(userApi.getFirms());
    }

    if (displayedOrganization) {
      dispatch(fetchCurrentOrganization(displayedOrganization.organizationId));
    } else {
      dispatch(selectOrganization(activeFilters.organizationId));
      dispatch(resetDisplayedOrganizations(activeFilters as Filters));
    }
  }, [
    dispatch,
    displayedOrganization?.organizationId,
    activeFilters,
    navigate,
    organizations?.length,
  ]);

  useEffect(() => {
    if (containerRef.current) {
      addRef(containerRef);
    }
  }, [
    addRef,
    containerRef,
  ]);

  return (
    <>
      {isLoading ? <Spinner />
        : <div className={`firmContainer flex flex-col justify-between bg-black-150 ${valid && 'firm-container-with-bg'}`}>
          <div className={`
            ${valid && 'fixed-gradient'} flex h-full flex-col items-center gap-[1rem]`}
          >
            <FiltersComponent />
            <RouteMatcher
              fallbackElement={Error}
              subRoutes={ROUTE_ARRAY}
            />
            {showSupportModal && <Support />}
            {showDownloadsModal && <DownloadFilesModal />}
            {showPerformanceReportsModal && <ReportSelectorModal />}
            {showSettingsModal &&
              <BlockDisplayProvider><SettingsModule /></BlockDisplayProvider>}
            {fileViewModal.show && fileViewModal.id && <div className='breakpoints'>
              <FileViewModal
                allDocumentsInGroup={fileViewModal.allDocumentsInGroup}
                fileId={fileViewModal.id}
                fileNameProps={fileViewModal.fileNameProps}
              />
            </div>}
          </div>
        </div>
      }
    </>
  );
};

export const FirmWithModalContexts = () => <ModalActivationProvider>
  <GlobalElementBusContextProvider>
    <Firm />
  </GlobalElementBusContextProvider>
</ModalActivationProvider>;
