import { type FilesStore, type IndividualFileStore } from './FileViewModal';
import { documentsApi } from 'api/documentsApi';
import { actionCreator } from 'api/utils';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { type FC, type SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from 'store';
import { filesActionTypes } from 'store/actions';
import { type DocumentDTO, type DocumentGroupType, getItemsFromStorage, type LoginAsFromStorage } from 'types';

type FileOptionProps = {
  document: DocumentDTO,
  isSelected: boolean,
  onClick: (document: DocumentDTO) => void,
  typeGroups: DocumentGroupType[],
};

export const FileOption: FC<FileOptionProps> = ({
  document,
  isSelected,
  onClick,
  typeGroups,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {loginAs} = getItemsFromStorage<LoginAsFromStorage>(['loginAs'], sessionStorage);
  const {fileByIdLoading} = useSelector<FilesStore>((state) => state.files) as IndividualFileStore;
  const [userViewed, setUserViewed] = useState(document.userViewed);
  const parts = document.fileName.split('_');
  const {quarter, version, year} = document;
  const period = quarter && year ? `${quarter} ${year}` : year?.toString() || parts.at(-1) || '';
  const typeGroup = typeGroups.find((group) => group.documentGroupId === document.fileType);

  const downloadClick = async (event: SyntheticEvent | undefined) => {
    event?.stopPropagation();
    dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, true));
    await documentsApi.downloadDocument(document.fileName, document._id);
    dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, false));
    setUserViewed(true);
  };

  const renderDownloadButtonOnHover = () =>
    <div className='show-on-parent-hover left-0.75 size-1.5'>
      <Button
        className='!size-[1.375em]'
        data-test='download-file'
        icon='only'
        iconid='download-arrow'
        isLoading={fileByIdLoading}
        onClick={(event) => downloadClick(event)}
        size='medium'
        squared
      />
    </div>;

  const optionMainStyles = 'show-child-on-hover hide-child-on-hover flex cursor-pointer items-center gap-0.375 border-l-[2px] border-l-transparent px-0.625 py-0.5';
  const optionSelectedStyles = '!border-l-main-700 bg-main-100/20';
  const optionHoverStyles = 'hover:border-l-main-200 hover:bg-main-50 active:bg-main-100 active:border-l-main-700';

  return (
    <div
      className={`${optionMainStyles} ${isSelected ? optionSelectedStyles : optionHoverStyles}`}
      data-test={`file-option-${document._id}`}
      onClick={() => {
        if (!loginAs) {
          setUserViewed(true);
        }

        onClick(document);
      }}
    >
      {renderDownloadButtonOnHover()}
      <Svgicon
        className='hide-on-parent-hover flex size-1.5 items-center justify-center'
        id={userViewed || isSelected ? 'seen-recent-document-grey' : 'new-recent-document-grey'}
        text={document.fileType.slice(0, 3)}
      />
      <div className='flex flex-col gap-0.125'>
        <p className='mb-0 truncate text-font-3 text-black-999'>
          {`${typeGroup?.documentGroupName} ${version ? `#${version}` : ''} ${period}`}
        </p>
        <div className='flex gap-0.25'>
          <Svgicon className='size-0.625' id='luggage-04' />
          <div className='max-w-52'>
            <p className='acc-or-fund-name m-0 truncate text-caption-2 font-normal text-black-900'>
              {document.fundName}
            </p>
          </div>

        </div>
        <div className='flex gap-0.25'>
          <Svgicon className='size-0.625' id='user-profile-03' />
          <div className='max-w-52'>
            <p className='acc-or-fund-name m-0 truncate text-caption-2 font-normal text-black-900'>
              {document.accountName}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

