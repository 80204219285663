import { FileOption } from './FileOption';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import {type FC, useEffect, useRef } from 'react';
import { type DocumentDTO, type DocumentGroupType } from 'types';

type FileSelectorColumnProps = {
  buttonProps: {
    isLoading: boolean,
    isVisible: boolean,
    onClick: () => void,
    text: string,
  },
  documents: DocumentDTO[],
  onSelectDocument: (document: DocumentDTO) => void,
  selectedDocument: DocumentDTO | null,
  typeGroups: DocumentGroupType[],
};

export const FileSelectorColumn: FC<FileSelectorColumnProps> = ({
  buttonProps,
  documents,
  onSelectDocument,
  selectedDocument,
  typeGroups,
}) => {
  const selectedFileRef = useRef<HTMLDivElement>(null);

  // Effect to scroll to the selected file when component mounts or selectedFile changes
  useEffect(() => {
    if (selectedFileRef.current) {
      selectedFileRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [selectedDocument?._id]);

  return (
    <div className='relative flex h-full w-20 flex-col justify-between overflow-y-auto overflow-x-hidden'>
      <div className='flex flex-col justify-start'>
        {documents?.map((document) => {
          const isSelected = selectedDocument?._id === document._id;
          return (
            <div key={document._id} ref={isSelected ? selectedFileRef : null}>
              <FileOption
                document={document}
                isSelected={isSelected}
                onClick={onSelectDocument}
                typeGroups={typeGroups}
              />
            </div>
          );
        })}
      </div>
      {buttonProps.isVisible &&
        <div className='sticky bottom-0 px-0.75 py-0.5'>
          <Button
            className='w-full'
            data-test='download-documents'
            isLoading={buttonProps.isLoading}
            onClick={() => buttonProps.onClick()}
            size='medium'
            squared
            type='main'
          >{buttonProps.text}</Button>
        </div>
      }
    </div>
  );
};
