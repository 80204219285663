import { FileSelectorColumn } from './FileSelectorColumn';
import { documentsApi } from 'api/documentsApi';
import { actionCreator } from 'api/utils';
import { Spinner } from 'components';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Modal } from 'components/InteractiveUIControls/Modal/Modal';
import { PdfJsReader } from 'components/PdfReader/PdfJsReader';
import environment from 'config/environment';
import { ModalActivationContext } from 'contexts';
import {
  type FC,
  type SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'services/mobile';
import { type AppDispatch } from 'store';
import { filesActionTypes } from 'store/actions';
import {
  type DocumentsStoreState,
  type Filters,
  type FilterStoreState,
  handleFetchLatestDocuments,
  markDocumentsAsSeen } from 'store/slices';
import {
  type DocumentDetailsFromStorage,
  type DocumentDTO,
  type DocumentGroupType,
  getItemsFromStorage,
  type LoginAsFromStorage,
  removeItemsFromStorage } from 'types';

export type IndividualFileStore = {
  fileByIdLoading: boolean,
  viewingFileByIdLoading: boolean,
};

export type FilesStore = {
  files: IndividualFileStore,
};

type FileViewModalProps = {
  allDocumentsInGroup: DocumentDTO[],
  fileId: string | null,
  fileNameProps: {fileName: string, groupName: string,
    isRecentDocument: boolean,
    period: string,
  } | null,
};

export const FileViewModal: FC<FileViewModalProps> =
  ({allDocumentsInGroup, fileId, fileNameProps}) => {
    const navigate = useNavigate();
    const {pathname, search} = useLocation();
    const mobile = isMobile();
    const dispatch: AppDispatch = useDispatch();
    const queryParameters = new URLSearchParams(search);
    const {fileViewId} = Object.fromEntries(queryParameters.entries());
    const {
      fileByIdLoading,
    } = useSelector<FilesStore>((state) => state.files) as IndividualFileStore;
    const {loginAs} = getItemsFromStorage<LoginAsFromStorage>(['loginAs'], sessionStorage);
    const {categoryId, documentGroupId, documentId, isNewDocument} =
      getItemsFromStorage<DocumentDetailsFromStorage>([
        'categoryId',
        'documentGroupId',
        'documentGroupName',
        'documentId',
        'isNewDocument',
      ], sessionStorage);
    const {toggleDownloadsModal, toggleFileViewModal} = useContext(ModalActivationContext);

    const [fileUrl, setFileUrl] = useState<string | null>(null);

    const [selectedFileName, setSelectedFileName] = useState<string | null>(
      `${fileNameProps?.period} ${fileNameProps?.groupName}`,
    );
    const [selectedDocument, setSelectedDocument] = useState<DocumentDTO | null>(null);
    const typeGroups = useSelector<{ documents: DocumentsStoreState, }>(
      (state) => state.documents.newCategory
        ?.documentTypeGroups,
    ) as DocumentGroupType[];

    const { displayType,
      documentsPending: latestDocumentsPending,
      latestDocuments,
      pagination } = useSelector<{ documents: DocumentsStoreState, }>(
      (state) => state.documents,
    ) as DocumentsStoreState;

    const totalNumberOfLatestDocuments = useSelector<{ documents: DocumentsStoreState, }>(
      (state) => state.documents.newCategory?.totalNumberOfDocuments,
    ) as number;

    const allDocsLoaded = totalNumberOfLatestDocuments === latestDocuments?.length;

    const activeFilters = useSelector<{ filters: FilterStoreState, }>(
      (state) => state.filters.activeFilters,
    ) as Filters | null;

    useEffect(() => {
      if (fileId && allDocumentsInGroup?.length) {
        const currentDocument =
          allDocumentsInGroup.find((doc) => doc._id === fileId) || allDocumentsInGroup[0];
        setSelectedDocument(currentDocument);
      }
    }, [
      allDocumentsInGroup,
      fileId,
    ]);

    const getFileNameFromHeaders = useCallback(async (url: string) => {
      try {
        const response = await fetch(url, {
          credentials: 'include',
          method: 'HEAD',
        });
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="?(.+)/iu);
          if (fileNameMatch?.[1]) {
            setSelectedFileName(fileNameMatch[1]);
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching file name:', error);
      }
    }, []);

    useEffect(() => {
      (async () => {
        if (!selectedDocument?._id) {
          return;
        }

        dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, true));

        const url = `${environment.apiUrl}/api/v2/files/${selectedDocument._id}`;
        setFileUrl(url);

        const currentDocId = selectedDocument._id;
        if ((!loginAs || loginAs === 'false') && categoryId && documentGroupId && currentDocId && !selectedDocument.userViewed) {
          dispatch(markDocumentsAsSeen({
            categoryId,
            documentGroupId,
            documentIds: [currentDocId],
          }));
        }

        if (!selectedFileName) {
          await getFileNameFromHeaders(url);
        }

        dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, false));
      })();
    }, [
      categoryId,
      dispatch,
      documentGroupId,
      documentId,
      getFileNameFromHeaders,
      isNewDocument,
      loginAs,
      selectedDocument,
      selectedFileName,
    ]);

    const closeFileViewModal = () => {
      toggleFileViewModal(false);
      removeItemsFromStorage<DocumentDetailsFromStorage>([
        'categoryId',
        'documentGroupId',
        'documentGroupName',
        'documentId',
        'isNewDocument',
      ], sessionStorage);
      setFileUrl(null);
      if (fileViewId) {
        navigate(pathname);
      }
    };

    const downloadClick = async (event: SyntheticEvent | undefined) => {
      event?.stopPropagation();
      dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, true));
      if (isMobile()) {
        await documentsApi
          .downloadDocumentMobile(fileNameProps?.fileName as string,
            selectedDocument?._id as string);
      } else {
        await documentsApi
          .downloadDocument(fileNameProps?.fileName as string,
            selectedDocument?._id as string);
      }

      dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, false));
    };

    const titleForMultipleDocuments = () => {
      if (fileNameProps?.isRecentDocument) {
        return 'Recent documents';
      }

      return `${displayType.id === 'period' ? fileNameProps?.period : fileNameProps?.groupName} documents`;
    };

    const modalTitle = allDocumentsInGroup.length > 1 ?
      titleForMultipleDocuments() : selectedFileName;

    const titleForModal = () => <div className='flex w-full items-center justify-between pr-1.5'>
      <p className='m-0 whitespace-nowrap text-title-4 font-medium'>{modalTitle}</p>
      <Button
        className='w-max'
        data-test='download-file'
        icon='left'
        iconid='download-3f'
        isLoading={fileByIdLoading}
        onClick={(event) => downloadClick(event)}
        size='medium'
        tooltipWrapperClass='!w-fit'
        type='light-main'
      >Download document</Button>
    </div>;

    const titleForModalMobile = () => <div className='flex w-full items-center gap-0.5 pl-0.5'>
      <Button
        data-test='download-file'
        icon='only'
        iconClassName='size-1.5'
        iconid='download-3f'
        isLoading={fileByIdLoading}
        onClick={(event) => downloadClick(event)}
        tooltipWrapperClass='!size-1.75'
        type='transparent'
      > </Button>
      <p className='m-0 truncate text-font-1 font-medium leading-none'>{selectedFileName}</p>
    </div>;

    const isRecentDocument = fileNameProps?.isRecentDocument;

    const onFileSelectorButtonClick = () => {
      if (isRecentDocument && activeFilters) {
        handleFetchLatestDocuments({
          dispatch,
          displayType,
          filters: activeFilters,
          pagination,
        });
      } else if (displayType.id === 'period') {
        const year = fileNameProps?.period.split(' ')[1];
        if (year) {
          toggleDownloadsModal(true, year);
        }
      } else if (displayType.id === 'type' && documentGroupId) {
        toggleDownloadsModal(true, documentGroupId);
      }
    };

    return <Modal onToggle={closeFileViewModal} showCloseIcon >
      <div className='z-[1] py-0.625 pr-3 shadow-card-x3 dt:px-1.25 dt:pt-[0.675em] dt:shadow-none'>
        {mobile ? titleForModalMobile() : titleForModal() || <p>PDF Viewer</p>}
      </div>
      <div className='main-scroll h-[calc(100vh-3.5em)] overflow-hidden dt:h-[85vh] dt:w-[75em] dt:max-w-[90em]'>
        <div className='size-full overflow-hidden'>
          <div className='flex size-full justify-start'>
            {allDocumentsInGroup?.length > 1 && !mobile && <FileSelectorColumn
              buttonProps={{
                isLoading: latestDocumentsPending.pending,
                isVisible: !isRecentDocument || !allDocsLoaded,
                onClick: onFileSelectorButtonClick,
                text: isRecentDocument ? 'Load more' : `Download ${allDocumentsInGroup.length} documents`,
              }}
              documents={isRecentDocument ? latestDocuments || [] : allDocumentsInGroup}
              onSelectDocument={setSelectedDocument}
              selectedDocument={selectedDocument}
              typeGroups={typeGroups}
            />}
            <div className='size-full'>
              {fileUrl ? <PdfJsReader url={fileUrl} /> : <Spinner />}
            </div>
          </div>
        </div>
      </div>
    </Modal>;
  };
