import './PdfJsReader.scss';
import 'pdfjs-dist/web/pdf_viewer.css';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Spinner } from 'components/Spinner';
import * as pdfjsLib from 'pdfjs-dist';
// eslint-disable-next-line import/extensions
import PDFJSWorkerUrl from 'pdfjs-dist/build/pdf.worker?url';
import { TextLayerBuilder } from 'pdfjs-dist/web/pdf_viewer.mjs';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { isLandscape, isMobile } from 'services/mobile';

pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorkerUrl;

type PdfJsReaderProps = {
  url: string,
};

// Define Row outside of PdfJsReader to prevent re-creation on each render
type RowProps = {
  index: number,
  renderPage: (
    pageNum: number,
    canvasRef: React.RefObject<HTMLCanvasElement>,
    textLayerRef: React.RefObject<HTMLDivElement>,
    task: { cancel?: () => void, }
  ) => Promise<void>,
  style: React.CSSProperties,
};

type RenderTask = {
  cancel?: () => void,
};

const PAGE_GAP = 20;
const RENDER_SCALE = 1.5;
const PIXEL_RATIO = window.devicePixelRatio || 1;

/**
 * Returns the initial display scale factor based on the device orientation and screen width:
 * - 150% for landscape mode on large screens (width >= 1280px)
 * - 100% for landscape mode on medium screens (width < 1280px)
 * - 75% for portrait mode (mobile devices)
 */
const getInitialZoom = () => {
  if (isLandscape()) {
    return window.innerWidth < 1_280 ? 1 : 1.5;
  }

  return 0.725;
};

// Zoom constants
const MIN_ZOOM = 0.5;
const MAX_ZOOM = 3;
const ZOOM_STEP = 0.25;

const Row: React.FC<RowProps> = ({index, renderPage, style}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const textLayerRef = useRef<HTMLDivElement>(null);
  const renderTaskRef = useRef<RenderTask>({});

  useEffect(() => {
    // Cancel any previous render task
    renderTaskRef.current.cancel?.();

    // Create a new render task
    const task: RenderTask = {};
    renderTaskRef.current = task;

    renderPage(index + 1, canvasRef, textLayerRef, task);

    // Cleanup on component unmount or when index changes
    return () => task.cancel?.();
  }, [index, renderPage]);

  return (
    <div
      className='relative flex items-center justify-center overflow-hidden bg-grey-100'
      style={{
        ...style,
        paddingBottom: PAGE_GAP / 2,
        paddingTop: PAGE_GAP / 2,
      }}
    >
      <canvas className='bg-white' ref={canvasRef} />
      <div className='textLayer absolute' ref={textLayerRef} />
    </div>
  );
};

// Helper functions to set up the textLayer inside renderPage function
const clearTextLayer = (textLayerDiv: HTMLDivElement) => {
  while (textLayerDiv.firstChild) {
    textLayerDiv.removeChild(textLayerDiv.firstChild);
  }
};

const configureTextLayer = (
  textLayerDiv: HTMLDivElement,
  canvas: HTMLCanvasElement,
  viewport: pdfjsLib.PageViewport,
  zoomFactor: number,
) => {
  textLayerDiv.style.width = `${viewport.width}px`;
  textLayerDiv.style.height = `${viewport.height}px`;
  textLayerDiv.style.left = `${canvas.offsetLeft}px`;
  textLayerDiv.style.top = `${canvas.offsetTop}px`;

  // PDF.js uses --scale-factor CSS variable internally to calculate text positioning and sizing.
  // This ensures text elements are properly scaled to match the PDF's internal dimensions.
  // We tried to pass it to "TextLayerBuilder" as an option but we couldn't find a proper way as it's either it's poorly documented or we just missed.
  // As a solution we need to change the global css variable that this lib uses (--scale-factor).
  // For now we just override it here via textLayerDiv element
  textLayerDiv.style.setProperty('--scale-factor', zoomFactor.toString());
};

export const PdfJsReader: React.FC<PdfJsReaderProps> = ({ url }) => {
  const [pdf, setPdf] = useState<pdfjsLib.PDFDocumentProxy | null>(null);
  const [itemSize, setItemSize] = useState<number>(0);
  const listRef = useRef<List>(null);
  const [errorState, setErrorState] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [zoomFactor, setZoomFactor] = useState<number>(getInitialZoom());
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        setIsLoading(true);
        const loadingTask = pdfjsLib.getDocument({url, withCredentials: true});
        const loadedPdf = await loadingTask.promise;
        setPdf(loadedPdf);
      } catch {
        setErrorState(true);
      } finally {
        setErrorState(false);
        setIsLoading(false);
      }
    };

    loadPdf();
  }, [
    url,
  ]);

  useEffect(() => {
    const calculateItemSize = async () => {
      if (!pdf) {
        return;
      }

      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: RENDER_SCALE });
      const height = viewport.height * (zoomFactor / RENDER_SCALE);
      // Add gap to the item size
      setItemSize(height + PAGE_GAP);
    };

    calculateItemSize();
  }, [
    pdf,
    zoomFactor,
  ]);

  const setCanvasDimensions = useCallback((
    canvas: HTMLCanvasElement,
    viewport: pdfjsLib.PageViewport,
  ) => {
    const scaledFactor = zoomFactor / RENDER_SCALE;

    canvas.width = viewport.width * PIXEL_RATIO;
    canvas.height = viewport.height * PIXEL_RATIO;
    canvas.style.width = `${viewport.width * scaledFactor}px`;
    canvas.style.height = `${viewport.height * scaledFactor}px`;
  }, [zoomFactor]);

  const renderPage = useCallback(
    async (
      pageNum: number,
      canvasRef: React.RefObject<HTMLCanvasElement>,
      textLayerRef: React.RefObject<HTMLDivElement>,
      task: { cancel?: () => void, } = {},
    ) => {
      if (!pdf || !canvasRef.current || !textLayerRef.current) {
        return;
      }

      try {
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: RENDER_SCALE });
        const canvas = canvasRef.current;
        const textLayerDiv = textLayerRef.current;

        if (!canvas) {
          return;
        }

        setCanvasDimensions(canvas, viewport);
        const context = canvas.getContext('2d');

        if (!context) {
          return;
        }

        context.scale(PIXEL_RATIO, PIXEL_RATIO);

        // Store the render operation to allow cancellation
        const renderTask = page.render({ canvasContext: context,
          viewport });
        task.cancel = () => renderTask.cancel();

        await renderTask.promise;

        // If task was cancelled, don't proceed with text layer
        if (!task.cancel) {
          return;
        }

        clearTextLayer(textLayerDiv);
        configureTextLayer(textLayerDiv, canvas, viewport, zoomFactor);
        const textContent = await page.getTextContent();

        // Check if task was cancelled before continuing
        if (!task.cancel) {
          return;
        }

        const textLayerBuilder = new TextLayerBuilder({
          pdfPage: page,
        });

        await textLayerBuilder.render(viewport, { textContent });

        // Final check before appending to DOM
        if (task.cancel === undefined) {
          return;
        }

        textLayerDiv.appendChild(textLayerBuilder.div);
      } catch {
        // Ignore cancelled render errors
      }
    },
    [
      pdf,
      setCanvasDimensions,
      zoomFactor,
    ],
  );

  const handleZoomIn = useCallback(() => {
    setZoomFactor((prevZoom) => Math.min(prevZoom + ZOOM_STEP, MAX_ZOOM));
  }, []);

  const handleZoomOut = useCallback(() => {
    setZoomFactor((prevZoom) => Math.max(prevZoom - ZOOM_STEP, MIN_ZOOM));
  }, []);

  const toggleFullscreen = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    } else {
      containerRef.current.requestFullscreen({navigationUI: 'show'});
      setIsFullscreen(true);
    }
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(Boolean(document.fullscreenElement));
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  if (errorState) {
    return (
      <div className='flex size-full items-center justify-center text-title-1'>
        Unable to load PDF
      </div>
    );
  }

  return (
    <div
      className='bg-grey-100'
      ref={containerRef}
      style={{
        boxSizing: 'border-box',
        height: '100%',
        position: 'relative',
        width: '100%',
      }}
    >
      {!isLoading && pdf && itemSize ?
        <>
          {isMobile() ? null : <div className='mobile-font-24 absolute bottom-0 z-[1] flex w-full justify-center pb-1'>
            <div className='flex items-center gap-0.25 rounded-large bg-white-999 p-0.25 shadow-card-x3'>
              <Button
                className='!rounded-[100%]'
                disabled={zoomFactor <= MIN_ZOOM}
                icon='only'
                iconid='minus'
                onClick={handleZoomOut}
                size='normal'
                type='grey'
              />
              <span className='text-font-4 font-[600]'>{`${Math.round(zoomFactor * 100)}%`}</span>
              <Button
                className='!rounded-[100%]'
                disabled={zoomFactor >= MAX_ZOOM}
                icon='only'
                iconid='plus'
                onClick={handleZoomIn}
                size='normal'
                type='grey'
              />
            </div>
          </div>}
          {!isMobile() && <div className='absolute right-1.125 top-0.75 z-[1]'>
            <Button
              className='!rounded-[100%] shadow-card-x3'
              icon='only'
              iconid={isFullscreen ? 'arrow-collapse' : 'arrow-expand-3f'}
              onClick={toggleFullscreen}
              size='medium'
              type='white'
            />
          </div>}
          <AutoSizer>
            {({
              height,
              width,
            }: {
              // eslint-disable-next-line react/no-unused-prop-types
              height: number,
              // eslint-disable-next-line react/no-unused-prop-types
              width: number,
            }) =>
              <List
                height={height}
                itemCount={pdf.numPages}
                itemSize={itemSize}
                ref={listRef}
                width={width}
              >
                {({
                  index,
                  style,
                }: {
                  // eslint-disable-next-line react/no-unused-prop-types
                  index: number,
                  // eslint-disable-next-line react/no-unused-prop-types
                  style: React.CSSProperties,

                }) =>
                  <Row
                    index={index}
                    renderPage={renderPage}
                    style={style}
                  />
                }
              </List>
            }
          </AutoSizer>
        </>
        :
        <Spinner />
      }
    </div>
  );
};
